<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card elevation="2">
      <v-card-text>
        <div class="d-flex justify-end">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="success" @click="gotoCreate"
                ><v-icon>mdi-plus</v-icon> Document</v-btn
              >
            </template>
            <span>Add Document</span>
          </v-tooltip>
        </div>
        <v-data-table
          :headers="headers"
          :items="documents"
          item-key="templateId"
          class="elevation-1"
          :loading="loading"
          show-expand
          :expanded.sync="expanded"
          single-expand
        >
          <template v-slot:[`item.params`]="{ item }">
            <div>
              {{ item.params.length }}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex" v-if="item">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="error" @click="gotoDelete(item)"
                    ><v-icon small>mdi-delete</v-icon></v-btn
                  >
                </template>
                <span>Delete Document</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" color="info" @click="gotoEdit(item)"
                    ><v-icon small>mdi-pencil</v-icon></v-btn
                  >
                </template>
                <span>Edit Document</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div class="text-center font-weight-bold mt-2">Params</div>
              <v-divider></v-divider>
              <v-row class="tables my-2" justify="center">
                <v-col>
                  <template>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th class="text-center">Type</th>
                            <th class="text-center">Required</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(it, i) in item.params" :key="i">
                            <td>{{ it.name }}</td>
                            <td class="text-capitalize text-center">
                              {{ it.type }}
                            </td>
                            <td class="text-center">
                              <template v-if="it.required">
                                <v-icon color="success">mdi-check</v-icon>
                              </template>
                              <template v-else>
                                <v-icon color="error">mdi-close</v-icon>
                              </template>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
        <v-dialog
          v-model="dialogCreate"
          persistent
          :overlay="false"
          max-width="800px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title primary-title> Create Document </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    label="Template Uuid"
                    v-model="newDocument.templateId"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                    label="Name"
                    v-model="newDocument.name"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="text-center font-weight-bold">Params</div>
              <v-divider></v-divider>
              <div class="d-flex flex-column">
                <div
                  class="d-flex"
                  v-for="(param, i) in newDocument.params"
                  :key="i"
                >
                  <div class="col-sm-2">
                    <v-switch
                      inset
                      :readonly="!edit"
                      label="Required"
                      v-model="param.required"
                    ></v-switch>
                  </div>
                  <div class="col-sm-5 ma-1">
                    <v-text-field
                      label="Name"
                      outlined
                      dense
                      :readonly="!edit"
                      v-model="param.name"
                    ></v-text-field>
                  </div>
                  <div class="col-sm-4 ma-1">
                    <v-select
                      outlined
                      :readonly="!edit"
                      item-text="label"
                      item-value="value"
                      dense
                      :items="itemstypes"
                      v-model="param.type"
                      label="Type"
                    ></v-select>
                  </div>
                  <div class="col-sm-1">
                    <v-btn
                      class="my-2"
                      icon
                      color="error"
                      @click="deleteParam(i)"
                      ><v-icon>mdi-cancel</v-icon></v-btn
                    >
                  </div>
                </div>
                <v-divider></v-divider>
                <div class="d-flex">
                  <div class="col-sm-2">
                    <v-switch
                      inset
                      label="Required"
                      v-model="newParam.required"
                    ></v-switch>
                  </div>
                  <div class="col-sm-5 ma-1">
                    <v-text-field
                      label="Name"
                      outlined
                      dense
                      v-model="newParam.name"
                    ></v-text-field>
                  </div>
                  <div class="col-sm-4 ma-1">
                    <v-select
                      outlined
                      item-text="label"
                      item-value="value"
                      dense
                      :items="itemstypes"
                      v-model="newParam.type"
                      label="Type"
                      clearable
                    ></v-select>
                  </div>
                  <div class="col-sm-1 ma-1">
                    <v-btn
                      :disabled="!validParam"
                      icon
                      color="success"
                      @click="addParam"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="cancelAll">cancel</v-btn>
              <template v-if="!edit">
                <v-btn
                  :disabled="!validDocument || loading"
                  :loading="loading"
                  text
                  color="success"
                  @click="addDocument"
                  >create</v-btn
                >
              </template>
              <template v-else>
                <v-btn
                  :disabled="!validDocument || loading"
                  :loading="loading"
                  text
                  color="info"
                  @click="editDocument"
                  >Edit</v-btn
                >
              </template>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="deleteDocument.dialog"
          persistent
          :overlay="false"
          max-width="350px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title class="white--text red lighten-2" primary-title>
              Delete Document
            </v-card-title>
            <v-card-subtitle class="white--text red lighten-2">
              {{ deleteDocument.item ? deleteDocument.item.docName : "" }}
            </v-card-subtitle>
            <v-card-text class="text-center mt-4">
              <h3>Are you sure?</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelDelete">cancel</v-btn>
              <v-btn
                :disabled="loading"
                :loading="loading"
                text
                color="error"
                @click="confirmDelete"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text></v-card
    >
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
export default {
  name: "document-to-send",
  data() {
    return {
      loading: false,
      documents: [],
      expanded: [],
      dialogCreate: false,
      edit: false,
      newDocument: {
        templateId: null,
        name: null,
        params: [],
      },
      deleteDocument: {
        dialog: false,
        item: null,
      },
      newParam: {
        required: false,
        name: null,
        type: null,
      },
      headers: [
        { text: "Name", value: "docName", sortable: false },
        { text: "Params", value: "params", sortable: false, align: "center" },
        { text: "", value: "actions", sortable: false, align: "end" },
        { text: "", value: "data-table-expand", align: "end" },
      ],
      itemstypes: [
        { label: "Boolean", value: "boolean" },
        { label: "Date", value: "date" },
        { label: "Number", value: "number" },
        { label: "Phone", value: "phone" },
        { label: "String", value: "string" },
      ],
    };
  },
  computed: {
    validParam() {
      if (this.newParam.name != null && this.newParam.type != null) {
        return true;
      }
      return false;
    },
    validDocument() {
      if (
        (this.newParam.name != null &&
          this.newParam.name != undefined &&
          this.newParam.name != "") ||
        this.newParam.type != null
      ) {
        return false;
      }
      if (
        this.newDocument.name != null &&
        this.newDocument.templateId != null
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    gotoCreate() {
      this.dialogCreate = true;
      this.edit = false;
      this.newDocument = { templateId: null, name: null, params: [] };
    },
    gotoEdit(item) {
      this.dialogCreate = true;
      this.edit = true;
      this.newDocument = {
        templateId: item.templateId,
        name: item.docName,
        params: item.params,
      };
    },
    addParam() {
      const body = Object.assign({}, this.newParam);
      this.newDocument.params.push(body);
      this.newParam = { required: false, name: null, type: null };
    },
    deleteParam(index) {
      this.newDocument.params.splice(index, 1);
    },
    addDocument() {
      const bodyreq = Object.assign({}, this.newDocument);
      this.loading = true;
      getAPI
        .post("/sprocedures/createDocsToSend", bodyreq)
        .then(() => {
          this.loading = false;
          this.cancelAll();
          this.getDocuments();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    editDocument() {
      const templateId = this.newDocument.templateId;
      const bodyreq = Object.assign({}, this.newDocument);
      delete bodyreq.templateId;
      this.loading = true;
      getAPI
        .put("/sprocedures/updateDocsToSend/" + templateId, bodyreq)
        .then(() => {
          this.loading = false;
          this.cancelAll();
          this.getDocuments();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getDocuments() {
      this.loading = true;
      getAPI("sprocedures/getDocsToSend")
        .then((res) => {
          this.loading = false;
          this.documents = res.data;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    cancelAll() {
      this.dialogCreate = false;
      this.newDocument = { templateId: null, name: null, params: [] };
      this.loading = false;
      this.newParam = { required: false, name: null, type: null };
      this.getDocuments();
    },

    //Delete
    gotoDelete(item) {
      this.deleteDocument = { dialog: true, item: item };
    },
    cancelDelete() {
      this.deleteDocument = { dialog: false, item: null };
    },
    confirmDelete() {
      this.loading = true;
      getAPI
        .delete(
          "/sprocedures/deleteDocsToSend/" + this.deleteDocument.item.templateId
        )
        .then(() => {
          this.loading = false;
          this.getDocuments();
          this.cancelDelete();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.tables {
  width: 100% !important;
}
</style>
